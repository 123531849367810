<template>
<a-form :form="form" @submit="handleSubmit" class="p-form">
  <div class="register">
  	<div class="box-left">
  		<div class="logo"><img :src="logo" style=""/></div>
  		<div class="title">
  			BEGIN YOUR <br/>CO-CREATION <br/>JOURNEY
  		</div>
  		<div class="desc">
  			<!--Create an account through<br/>which you can submit your<br/>tenancy application-->
  		</div>
  	</div>
  	<div class="form-box">
  		<div class="block-nav">
  			<a href="javascript:;" @click="$router.push({path: '/'});">Back to Home</a>
  			<a href="javascript:;" @click="$router.push({path: '/'});">繁</a>
  			<a href="javascript:;" @click="$router.push({path: '/'});">简</a>
  		</div>
  		<h3 class="form-title">Log In</h3>
  		<div class="form">
  			
          <a-alert
            v-if="isError"
            :message="errorMessage"
            type="error"
            banner
            closable
          />
            <a-form-item label="Email">
                <a-input
                  class="form-control"
                  v-decorator="['email', { rules: [
                  {type: 'email',message:'The input is not valid E-mail!'},
                  { required: true, message: 'Please input your E-mail!' }]
                   }]"
                />
              </a-form-item>
              <a-form-item label="Password" style="margin-bottom: 5px;">
                <a-input
                  class="form-control"
                  type="password"
                  v-decorator="['password', { rules: [{ required: true, message: 'Please input your password!' }] }]"
                />
              </a-form-item>
            
            <div class="form-group"><a href="javascript:;" @click="$router.push({path: '/findpassword'});"  class="tinoe">forgot password?</a></div>
            <div class="form-group" style="margin-top:40px;">
                <button :disabled="btnDisabled" class="btn btn-info btn-block" type="submit" >
                    <a-spin :indicator="indicator" v-if="btnLoad"/> 
                    Log in</button>

            </div>
            <div class="form-group">
            	<p class="m-notice">Don't have an account yet? <a href="javascript:;" @click="showRegisterView">Sign Up</a></p>
            </div>
  		</div>
  	</div>
  </div>
  <div v-if="isLoad">
    <a-spin />
  </div>
  <RegisterNotice v-if="signshowviewHtml"></RegisterNotice>
</a-form>
</template>
<script>
import global from '../global'
import jq from 'jquery'
import RegisterNotice from '@/components/RegisterNotice'
export default {
    name: 'Login',
    components: {
        RegisterNotice,
    },
    data() {
        return {
           logo:require('../assets/img/Logos@2.png'),
           form: this.$form.createForm(this, { name: 'coordinated' }),
           isLoad:false,
           errorMessage:'',
           isError:false,
           signshowviewHtml:false,
           makeHtml:'',
           btnLoad:false,
          btnDisabled:false,
          indicator:<a-icon type="loading" style="font-size: 16px" spin />,
        }
    },
    mounted() {
        this.$cookies.remove('_TOKEN_')
        this.$cookies.remove('_USER_')
    },
    methods: {
      handleSubmit(e){
          e.preventDefault();
          let that = this;
          that.isError = false;
          this.form.validateFields((err, values) => {
              if (!err) {
                that.isLoad = true;
                that.btnDisabled = that.btnLoad = true;
                jq.post(global.api.domain+"forntend/login.do",values,function(response){
                    that.isLoad = false;
                    that.btnDisabled = that.btnLoad = false;
                    if(response.code == 0){
                       that.$cookies.set('_USER_',response.data);
                       that.$cookies.set('_TOKEN_',response.data.token);
                       that.$message.success(
                          'Login successful',
                          1,
                        ).then(()=>{
                           window.location.href = "/#/welcome"
                        });

                    }else{
                        if(response.msg != null){
                            that.isError = true;
                            that.errorMessage = 'You already have an account, please go back to the login page';
                        }
                    }
                    console.log('Received values of form: ', response);
                })
                
            }
          });
        },
        showRegisterView(){
            if(this.makeHtml == null || this.makeHtml == ''){
              this.makeHtml = document.createElement("div");
              this.makeHtml.classList.add("dialog-make");
              this.makeHtml.id = "_makeHtml";
              document.body.appendChild(this.makeHtml);
            }
            this.signshowviewHtml = true;
        },
    }
}
</script>
<style>
	body{
		background: #F1F1F1;
	}
	#app{
		height:99.8%;
		background: #F1F1F1;
	}
	.main-box{
		height:100%;
		
	}
	.content{
			height:100%;
		}
@media screen and (max-width:1079px){
	#app{
		background: #F1F1F1;

	}
}
</style>